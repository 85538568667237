<template>
  <div class="tw-flex tw-justify-center">
    <div class="sculptor">
      <div class="top-section tw-flex tw-items-center">
        <p>Units</p>
        <v-btn class="add-unit-btn ml-3" @click="$router.push({name: 'AddUnit'})">
          <icons name="add-unit" class="mr-2" /> Add Units
        </v-btn>
      </div>

      <div>
        <div class="total-unit">
          <div class="tw-flex tw-items-center">
            <icons name="units" />
            <span class="darker-text ml-1">Total Units</span>
            <v-spacer></v-spacer>
            <span class="lighter-text">Today</span>
          </div>
          <p class="total-unit-figure mt-3">{{totalUnits}}</p>
          <div class="tw-flex tw-justify-end">
            <icons name="temp-graph" />
          </div>
        </div>
        <div class="company-dashboard lg:tw-mt-10 tw-mt-5 tw-p-5">
          <div class="tw-flex tw-justify-between tw-w-full tw-items-center tw-flex-wrap">
            <h6 class="header">All Units</h6>
            <div class="mr-2 tw-mt-5 lg:tw-mt-0 " style="border-radius: 10px; border: 1px solid rgba(79, 79, 79, 0.08)">
              <v-text-field placeholder="Search" class="p-0" solo flat hide-details v-model="search">
                <template #prepend>
                  <v-icon :color="color" class="ml-5">
                    mdi-magnify
                  </v-icon>
                </template>

                <template #append>
                  <v-icon :color="color">
                    mdi-filter-variant
                  </v-icon>
                </template>
              </v-text-field>
            </div>
            <v-data-table :headers="headers" :mobile-breakpoint="0" :search="search" item-key="id"
              :single-select="false" height="available" :hide-default-footer="units.length <= 7" :items="units"
              :no-data-text="not_found" class="mt-4 mx-1 table" :loading="loading" @click:row="routeToPage">
              <template #header.profile>
                <span class="status">Profile</span>
              </template>

              <template v-slot:[`item.profile`]="{ item }">
                <div class="circle">
                  <span class="td-style">{{ getFirstLetter(item.name) }}</span>
                </div>
              </template>
              <template #header.unit>
                <span class="status">Unit</span>
              </template>

              <template v-slot:[`item.unit`]="{ item }">
                <span class="td-style">{{ item.name }}</span>
              </template>

              <template #header.unitHead>
                <span class="status">Unit Head</span>
              </template>

              <template v-slot:[`item.unitHead`]="{ item }">
                <span class="td-style">{{ item.createdBy }}</span>
              </template>

              <template #header.departments>
                <span class="status">Departments</span>
              </template>

              <template v-slot:[`item.departments`]="{ item }">
                <span class="td-style">{{getTotalDept(item.departments)  }}</span>
              </template>

              <template #header.paddlers>
                <span class="status">Paddlers</span>
              </template>

              <template v-slot:[`item.paddlers`]="{ item }">
                <span class="td-style">{{getTotalPaddlers(item.departments)  || "0"}}</span>
              </template>
              <template #header.actions>
                <span class="status">Actions</span>
              </template>

              <template v-slot:[`item.actions`]="{item}">
                <v-menu bottom offset-y left>
                  <template v-slot:activator="{ on, attrs }">
                    <td class="d-flex justify-end">
                      <v-btn v-bind="attrs" v-on="on" icon>
                        <v-icon :color="color">mdi-dots-horizontal</v-icon>
                      </v-btn>
                    </td>
                  </template>
                  <v-list>

                    <v-list-item class="" @click="routeToPage(item)">
                      <v-list-item-title style="color: black"
                        class="tw-flex-row tw-flex tw-cursor-pointer lighter-text">
                        <icons name="view-paddler" class="mr-2" />
                        View unit
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item class="" @click="routeToUpdateUnit(item)">
                      <v-list-item-title style="color: black"
                        class="tw-flex-row tw-flex tw-cursor-pointer lighter-text">
                        <icons name="update-paddler" class="mr-2" />
                        Update unit
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Icons from '../../../components/reuseables/Icons.vue'
  import {
    getAllUnits
  } from '../../../services/api/APIService';
  export default {
    name: "AllPaddlers",
    components: {
      Icons
    },
    data() {
      return {
        color: "#004AAD",
        search: "",
        units: [],
        not_found: "Units will show here",
        headers: [{
            text: "",
            value: "profile"
          }, {
            text: "",
            value: "unit"
          },
          {
            text: "",
            value: "unitHead"
          }, {
            text: "",
            value: "departments"
          }, {
            text: "",
            value: "paddlers"
          },
          {
            text: "",
            value: "actions",
            align: "end"
          },
        ],
        loading: false,
        totalUnits: 0,
      }
    },

    methods: {
      getFirstLetter(unit) {
        return unit.charAt(0)
      },
      getTotalDept(departments) {
        return departments ? departments.length : 0;
      },
      getTotalPaddlers(departments){  
        departments.forEach( department =>{
        this.totalPaddlers += department.totalPaddlers })
        return this.totalPaddlers
      },
      async getAllUnits() {
        try {
          this.loading = true;
          const response = await getAllUnits();
          this.units = response.data;
        } catch (error) {
          this.handleError(error);
        } finally {
          this.loading = false;
        }
      },

      routeToUpdateUnit(item) {
        this.$store.dispatch("paddler/setSelectedUnit", item)
        this.$router.push({
          name: "UpdateUnit"
        })
      },
      handleError(error) {
        this.$displaySnackbar({
          message: error.response.data.details[0] || 'An error occurred',
          success: false
        });
      },
      routeToViewUnit() {
        this.$router.push({
          name: 'ViewUnits'
        })
      },
      // routeToEditUnits(item) {
      //   this.$store.dispatch("paddler/setSelectedPaddler", item)
      // },

      routeToPage(item) {
        this.$store.dispatch("paddler/setSelectedUnit", item)
        this.$router.push({
          name: "ViewUnits"
        })
      },

    },
    async created() {
      await this.getAllUnits()
    },

  }
</script>

<style lang="scss" scoped>
  .sculptor {
    width: 1000px;

    @media (max-width: 1024px) {
      width: 100%;
    }
  }

  .top-section p {
    color: #263238;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
  }

  .add-unit-btn {
    display: flex;
    color: #004AAD;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
    width: fit-content;
    height: fit-content;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    letter-spacing: 0px;
    padding: 12px;
    border-radius: 10px;
  }

  .add-unit-btn:hover {
    background-color: #c1bfbf4c;
  }

  .total-unit {
    width: 330px;
    height: 150px;
    border-radius: 10px;
    padding: 22px;
    margin-top: 20px;
    background: #FEFFFF;
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.03);
  }

  .categories-box {
    width: 200px;
    height: fit-content;
    padding: 10px 15px;
    flex-shrink: 0;
    margin-top: 20px;
    border-radius: 10px;
    background: #FEFFFF;
    margin-left: 20px;
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.03);

    @media (max-width: 650px) {
      margin-left: 0;
    }
  }

  .categories-box,
  .total-unit {
    @media (max-width: 650px) {
      width: 100%;
    }
  }

  .darker-text,
  .lighter-text {
    color: #263238;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .lighter-text {
    color: #838383;
  }

  .total-unit-figure {
    color: #4F4F4F;
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .small-darker-text {
    font-size: 16px;
  }

  .status-tags {
    font-family: Inter;
    font-size: 9px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .company-dashboard {
    display: flex;
    background: #FFFFFF;
    width: 100%;
    min-height: 70vh;
    max-height: available;
    border-radius: 10px;
    flex-direction: column;
  }

  .card-sculptors {
    @media (max-width: 650px) {
      flex-direction: column;
    }
  }

  .header {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #004AAD;

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  .circle {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background-color: #7CB0F6;
    /* Set your desired background color here */
    border-radius: 50%;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: white;

  }

  .table {
    width: 100%;
  }

  .v-data-table__wrapper>table {
    margin-top: 55px !important;
  }

  .theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr>th:first-child,
  .v-data-table>.v-data-table__wrapper>table>tbody>tr>td:first-child {
    padding-left: 16px;
  }

  .theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr>th,
  .status {
    color: #939393;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .v-data-table>.v-data-table__wrapper>table>tbody>tr>td,
  .td-style {
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 15px;
    letter-spacing: 0;
    text-align: left;
    text-transform: capitalize;
  }

  .company-logo {
    height: 100px;
    width: 100px;
    border-radius: 10px;
    object-fit: contain;
  }

  .no-image {
    display: flex;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-style: normal;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    text-align: center;
  }
</style>